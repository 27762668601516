import React from "react";
import {
  ContainerContent,
  ContainerContentFixed,
  ContainerCta,
  ContentTitle,
  InternalLink,
  InternalLinkTypes,
  Layout,
  SEO,
} from "../../components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import "./blog.css";

const Blog = () => {
  const data = useStaticQuery(graphql`
    query {
      BlogImage: file(relativePath: { eq: "blogs/blog5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        title={"Do You Need a Perfect Butler’s Pantry With Your Dream Kitchen?"}
      />
      <ContainerContent style={{ padding: 0 }}>
        <Img
          fluid={data.BlogImage.childImageSharp.fluid}
          style={{ marginBottom: "8px", maxHeight: 500 }}
        />
      </ContainerContent>
      <ContainerContent>
        <ContainerContentFixed>
          <ContentTitle
            title={
              "Do You Need a Perfect Butler’s Pantry With Your Dream Kitchen?"
            }
          />
          <div className={"cp-blog"}>
            <p>
              <b>
                The right single custom home builder can bring your butler’s
                pantry dream to life.
              </b>
            </p>

            <p>
              These days you can get the dream home you’ve always wanted with
              all the special touches you would love. From an island bench with
              its own sink and tapware to unique pendant lighting hanging from a
              timber featured ceiling – there’s no end to the possibilities.
            </p>

            <p>
              The kitchen has always been a focal point, especially for
              Australian homes. It’s often the most used space in anyone’s place
              and offers you the chance to showcase your own style with a keen
              eye on function.{" "}
            </p>

            <p>
              The changes in Australian kitchens has seen many innovations over
              the years and design traits continue to evolve in the latest
              contemporary fashion.
            </p>

            <p>
              There’s a growing trend across Melbourne for a butler’s pantry to
              be a key part of a new home. It’s a modern twist on an old
              favourite that’s becoming more popular every day.
            </p>

            <p>
              <h4>What exactly is a Butler’s Pantry?</h4>
            </p>

            <p>
              Historically, a butler’s pantry was a small room where the family
              silver and formal china was stored. It was also an area for
              serving meals. Even though butlers are a thing of the past, the
              space has taken on a new form. It is now a preparation area for
              meals and a place for extra storage.
            </p>

            <p>
              The best way to think of it is as a kitchen within a kitchen. It
              can provide much needed extra storage and an area to prepare meals
              or snacks. The idea is to make your home a better place for
              entertaining and dining.
            </p>

            <p>
              As the host, you can both prepare food and clean up in a
              designated area that is out of view to your guests. Whether it’s
              to put together a few snacks or appetisers, mix drinks or just to
              store glasses, cutlery, the kettle or toaster and special linen, a
              butler’s pantry is a touch of luxury that works on more than one
              level.
            </p>

            <p>
              <h4>Planning a Butler’s Pantry – the Pros &amp; Cons</h4>
            </p>

            <p>
              Keeping your home neat and tidy is one of the trickiest battles to
              win.{" "}
            </p>

            <p>
              <h4>PROS</h4>
            </p>

            <ul>
              <li>Extra storage space</li>
              <li>Hide mess when you are entertaining</li>
              <li>Makes for better organisation of your kitchenware</li>
              <li>
                Less cleaning because you are not always using the entire
                kitchen
              </li>
              <li>
                A sleeker look because your kitchen is not cluttered with
                everyday items
              </li>
            </ul>

            <p>
              <h4>CONS</h4>
            </p>

            <ul>
              <li>The added cost</li>
              <li>Extra planning is needed</li>
              <li>Another space to keep tidy</li>
            </ul>

            <p>
              By planning the layout and storage needs for your butler’s pantry,
              you will save yourself time, money and a lot of headaches. Knowing
              what you want from this valuable space can mean you make more use
              of it in the long run.
            </p>

            <p>
              <h4>Organise the space</h4>
            </p>

            <p>
              By positioning the everyday items such as a toaster, kettle or
              glassware in easy reach will give you the best use of the space.
            </p>

            <p>
              If you want to create a working space in your butler’s pantry,
              then you can prepare meals and use it as a drinks serving area.
              It’s the perfect spot for wine racks or wine coolers. It can be
              home to coffee making and juicers that take up valuable kitchen
              bench space.
            </p>

            <p>
              This allows you to have your own prep room whenever you entertain
              and everything is out of sight from your guests. Preparing food
              and drinks in privacy is less stressful when you aren’t being
              watched.
            </p>

            <p>
              You also have the ability to clear dishes and glasses away and
              deal with them when you want to without the unsightly look and
              clutter killing the mood.
            </p>

            <p>
              <h4>Where does your Butler’s Pantry start?</h4>
            </p>

            <p>
              It might seem hard to know where to begin with getting your
              butler’s pantry happening, but with the right single custom home
              builder on your side – it can all be yours.
            </p>

            <p>
              When you choose a custom built home, you’re taking the design into
              one that is tailor-made to you and your needs. There’s no risk of
              having a single space in your home that’s not to your liking.
            </p>

            <p>
              Your dream kitchen can come to life with the added bonus of a
              butler’s pantry to make it perfect.
            </p>

            <p>
              That’s where the best custom designer homes builder Melbourne is
              all talking about comes to the fore.{" "}
            </p>

            <p>The name to remember is Grandeur Homes.</p>

            <p>
              <h4>The Grandeur Homes specialty</h4>
            </p>

            <p>
              Whether it’s the special lounge, home theatre or the magic touch
              of a butler’s pantry that you’re after, Grandeur Homes are the
              single custom home builder you can trust to bring your dream home
              to life.
            </p>

            <p>
              You can see some of the{" "}
              <InternalLink url={InternalLinkTypes.FloorPlans}>
                fantastic floor plans here
              </InternalLink>{" "}
              for inspiration.
            </p>

            <p>
              This is not just a home built for you and your family, this is
              your very own slice of heaven. All the work goes into meticulous
              planning and design right through to when you have the keys in
              your hand and you open the door to the perfect place for you to
              live in and enjoy.
            </p>

            <p>
              One look at a Grandeur Home and you will see why they are the
              custom designer homes builder Melbourne new home owners are raving
              about.{" "}
            </p>

            <p>
              The professionals at Grandeur Group are always working for you
              with your interests at the heart of everything they do.
            </p>

            <p>
              Talk to the expert team today and get your dream home underway.
              Start living in the home you’ve had your heart set on –{" "}
              <InternalLink url={InternalLinkTypes.Contact}>
                Contact Grandeur Homes{" "}
              </InternalLink>
              today.
            </p>
          </div>
        </ContainerContentFixed>
      </ContainerContent>
      <section>
        <ContainerCta
          title={"Your future happiness is waiting"}
          subTitle={"Start building your custom Grandeur Home"}
          primaryLink={InternalLinkTypes.GetStarted}
          primaryText={"Get Started"}
          secondaryLink={InternalLinkTypes.Blogs}
          secondaryText={"Read other blogs"}
        />
      </section>
    </Layout>
  );
};

export default Blog;
